import {
    Component,
    ComponentFactoryResolver,
    OnInit,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { ScriptLoaderService } from '../_services/script-loader.service';
import { AuthenticationService } from './_services/authentication.service';
import { AlertService } from './_services/alert.service';
import { AlertComponent } from './_directives/alert.component';
import { LoginCustom } from './_helpers/login-custom';
import { Helpers } from '../helpers';
import * as moment from 'moment';
import { HelperService } from '../admin/_services/helper.service';
import { FranquiaService } from '../admin/_services/franquia.service';
import { VoucherService } from '../admin/_services/voucher.service';
import { EstabelecimentoService } from '../admin/_services/estabelecimento.service';
import { OrganizadorService } from '../admin/_services/organizador.service';
import { RdStationService } from '../admin/_services/rdStation.service';
import { BehaviourService } from '../admin/_services/behaviour.service';
import { cpf, cnpj } from 'cpf-cnpj-validator';
import { formatDate } from '@angular/common';
import { FormControl, FormsModule } from '@angular/forms'
import { AuthService } from '../admin/_services/auth.service';
import { UserService } from '../admin/_services/user.service';
import { environment } from '../../../src/environments/environment.prod';
// import { ReCaptchaV3Service } from 'ng-recaptcha';


// interface WebAppInterface {
//     MostrarMensagem(toast: string) : any; // 4
//     MostrarContatos() : any; // 4
// }

@Component({
    selector: '.m-grid.m-grid--hor.m-grid--root.m-page',
    templateUrl: './templates/login-1.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class AuthComponent implements OnInit {
    private URL = `${environment.domain}`;
    resolved(captchaResponse: string) {
        // console.log(`Resolved captcha with response: ${captchaResponse}`);
        this.cap = captchaResponse;
        // console.log("cap", this.cap)
    }
    model: any = {};
    cap: any
    loading = false;
    returnUrl: string;
    regioes: any = [];
    lat: string;
    long: string;
    coords: any;
    semanas: any = [];
    semana: any;
    user: any = {};
    codigo: any;
    //Estabelecimento
    estabelecimento: any = {};
    categorias: any = ["Vestuário", "Sapatos", "Farmácias", "Loja de departamento", "Brinquedos", "Enxoval", "Decoração", "Móveis", "Festas", "Autonomo", "Mercados", "Amamentação"];
    modalidades: any = ["Físico", "Ecommerce", "Físico e Ecommerce", "Venda direta"];
    //Organizador
    organizador: any = {};
    cadastro: any = "Usuario";
    // Captacao
    comoConheceu: any = ["Blogs",
        "Chá de uma amiga",
        "Consultório",
        "Empresa",
        "Eventos",
        "Feira Gestante",
        "Google",
        "Indicação",
        "Instagram",
        "Livelo",
        "Podcast",
        "Outros",
        "YouTube"];

    //

    @ViewChild('alertSignin', { read: ViewContainerRef, static: true }) alertSignin: ViewContainerRef;
    @ViewChild('alertSignup', { read: ViewContainerRef, static: true }) alertSignup: ViewContainerRef;
    @ViewChild('alertForgotPass', { read: ViewContainerRef, static: true }) alertForgotPass: ViewContainerRef;

    constructor(
        private userService: UserService,
        private authService: AuthService,
        private _router: Router,
        private _script: ScriptLoaderService,
        private _route: ActivatedRoute,
        private _authService: AuthenticationService,
        private _alertService: AlertService,
        private toastr: ToastrService,
        private _franquiaService: FranquiaService,
        private _helperService: HelperService,
        private _estabelecimentoService: EstabelecimentoService,
        private cfr: ComponentFactoryResolver,
        private _behaviour: BehaviourService,
        private _voucher: VoucherService,
        private _organizador: OrganizadorService,
        private _rdSationService: RdStationService,
        private _userService: UserService
    ) {
    }

    // public executeImportantAction(): void {
    //     this.recaptchaV3Service.execute('importantAction')
    //       .subscribe((token) => this.handleToken(token));
    //   }

    ngOnInit() {
        this.model.remember = true;
        this.model.apresentacao = "mamãe";
        this.model.tipo = "gestante";
        this.model.sexoBebe = "Menino";
        this.model.checkCodigo = false;
        this.model.codigo = "";
        // get return url from route parameters or default to '/'
        this.returnUrl = this._route.snapshot.queryParams['returnUrl'] || '/';
        //this._router.navigate([this.returnUrl]);

        this._script.loadScripts('body', [
            'assets/vendors/base/vendors.bundle.js',
            'assets/demo/default/base/scripts.bundle.js'], true).then(() => {
                Helpers.setLoading(false);
                LoginCustom.init();
            });
        for (var i = 42; i >= 1; i--) {
            if (i != 0) {
                this.semanas[i] = i;
            }
        }
        this.semanas.shift();

        this._franquiaService.getFranquia({}).then((data: any) => {
            this.regioes = data.payload;
        })
        // var auxCoords;
        // if (navigator.geolocation) {
        //     var infoCoords = navigator.geolocation.getCurrentPosition(
        //         function(position) {
        //             return position;
        //         },
        //         function errorCallback(error) {
        //             //do error handling
        //         }
        //     );  
        // } 
        if (sessionStorage.getItem('cadastro') != undefined) {
            document.getElementById('m_login_signup').click();
            LoginCustom.displaySignUpForm()
            //alert('oi')
        }
    }
    loginClick() {
        LoginCustom.displaySignInForm();
    }
    formatDate(date) {
        if (date) {
            var dateSent = new Date(date);
            var startTime = moment(dateSent).format("DD/MM/YYYY");
            return startTime;
        }
    }

    formatDateBD(date) {
        if (date) {
            var dateSent = new Date(date);
            var startTime = moment(dateSent).format("YYYY/MM/DD");
            return startTime;
        }
    }

    async signin() {
        this.loading = true;
        if (!this.cap) {
            this.toastr.error("Por favor, verifique que não é um robô")
            this.loading = false;
            return;
        }
        var refRemarketing;
        var token;
        var obj = { "email": this.model.email, "pass": this.model.password }
        await this.authService.login(obj).then(data => {
            this.user = data;
            console.log("user", this.user);
        }).catch(function (e) {
            return false;
        });
        if (this.user.payload) {
            if (this.user.payload.length == 0) {
                this.toastr.error("E-mail ou senha inválida.");
                this.loading = false;
                return;
                this._alertService.error(
                    'E-mail ou senha inválida.',
                    true);
            }
            if (this.user.payload[0].autenticacaoDoisFatores == 1) {
                document.getElementById("openModalVerificacao").click();
                this.loading = false;
                return;
            }
            localStorage.setItem('pipoohUser', this.user.payload[0].token);
            refRemarketing = localStorage.getItem('pipoohRefRemarketing');
            token = localStorage.getItem('pipoohUser');
            if (this.user.payload[0].organizador == "1") { // login de organizador 
                this._router.navigate(['/organizador']);
            }
            else { // login de usuario normal
                this._behaviour.loginRegistro({ "token": token });
                if (refRemarketing != null) {
                    var servico = this._authService.novaRef(refRemarketing, token);
                }
                if (this.user.payload[0].onboarding) {
                    var url = this.URL;
                    window.location.href = url;
                    // this._router.navigate([this.returnUrl]);
                } else {
                    this._router.navigate(['/boasVindas']);
                }
            }
        }
        else {
            this._alertService.error(
                'E-mail ou senha inválida.',
                true);
        }
        // this._authService.login(this.model.email, this.model.password).then(data => {
        //     //console.log("Info do backend: ",data);
        //     if (data == false) {
        //         this._alertService.error(
        //             'E-mail ou senha inválida.',
        //             true);

        //     }
        //     else {
        //         console.log("data", data);

        //         refRemarketing = localStorage.getItem('pipoohRefRemarketing');
        //         token = localStorage.getItem('pipoohUser');
        //         this._behaviour.loginRegistro({ "token": token });
        //         if (refRemarketing != null) {
        //             var servico = this._authService.novaRef(refRemarketing, token);
        //         }
        //         this._router.navigate([this.returnUrl]);
        //     }
        // })

        this.showAlert('alertSignin');
        this.loading = false;
    }
    validarCodigo() {
        this.authService.validarCodigo({ "fkUsuario": this.user.payload[0].UserID, "codigo": this.codigo }).then(data => {
            console.log("user", this.user.payload[0].token)
            if (data.payload.length > 0) {
                var refRemarketing;
                var token;
                localStorage.setItem('pipoohUser', this.user.payload[0].token);
                refRemarketing = localStorage.getItem('pipoohRefRemarketing');
                token = localStorage.getItem('pipoohUser');
                this._behaviour.loginRegistro({ "token": token });
                if (refRemarketing != null) {
                    var servico = this._authService.novaRef(refRemarketing, token);
                }
                this._router.navigate([this.returnUrl]);
            }
            else {
                this.toastr.error("Código inválido!");
            }
        })
    }
    signUp() {
        this.loading = true;
        // return;
        if (!this.cap) {
            this.toastr.error("Por favor, verifique que não é um robô")
            this.loading = false;
            return;
        }
        var aux: any;
        if (this.model.tipo == "gestante") {
            if (this.semana == undefined || this.semana == "") {
                this.toastr.error('Semana é obrigátoria', 'Falha no cadastro');
                this.loading = false;
                return false;
            }
            else {
                var date = new Date;
                var dias = parseInt(this.semana) * 7;
                date.setDate(date.getDate() - dias);
                this.model.ultimaMenstruacao = this.formatDateBD(date);
            }
            if (this.model.ultimaMenstruacao == undefined || this.model.ultimaMenstruacao == "") { this.toastr.error('Data da menstruação é obrigátoria', 'Falha no cadastro'); this.loading = false; return false; }
        };
        if (this.model.codigo != "" && this.model.codigo != null) {
            this._authService.codigoCheck(this.model.codigo).then(data => {
                aux = data;
                if (aux.payload == false) {
                    this._voucher.buscarVoucherRhCadastroUsuario({ "cpf": this.model.cpf, "codigo": this.model.codigo }).then(data => {
                        // if(data.payload.length == 0) {
                        //     this.toastr.error("Código inválido Front!");
                        //     return false;
                        // }
                        if (data.false) {
                            this.model.codigo = ""; this.loading = false; return false;
                        }
                    })
                }
            });

        }
        if (this.model.fullname == "") { this.toastr.error('Campo nome é obrigátorio', 'Falha no cadastro'); this.loading = false; return false; }
        var nome = this.model.fullname.split(" ");
        console.log("nome", nome.length)
        if (nome.length == 1) { this.toastr.error("Informe o nome completo", "Falha no cadastro"); this.loading = false; return; };
        if (this.model.apresentacao == "") { this.toastr.error('Campo apresentação é obrigátorio', 'Falha no cadastro'); this.loading = false; return false; }
        if (this.model.password != this.model.rpassword) { this.toastr.error('As senhas não coincidem', 'Falha no cadastro'); this.loading = false; return false; }
        if (this.model.password == "") { this.toastr.error('Campo senha é obrigátorio', 'Falha no cadastro'); this.loading = false; return false; }
        if (this.model.celular == "" || !this.model.celular) { this.toastr.error('Campo celular é obrigátorio', 'Falha no cadastro'); this.loading = false; return false; }
        // if(this.model.ultimaMenstruacao == undefined || this.model.ultimaMenstruacao == "") {this.toastr.error('Data da menstruação é obrigátoria','Falha no cadastro');this.loading = false;return false;}
        if (cpf.isValid(this.model.cpf) == false) { this.toastr.error('CPF inválido', 'Falha no cadastro'); this.loading = false; return false; }
        if (!this.model.captacao || this.model.captacao == "") { this.toastr.error('Informe onde conheceu a Pipooh', 'Falha no cadastro'); this.loading = false; return false; }

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                function (position) {
                    localStorage.setItem("USER_LATITUDE", position.coords.latitude.toString());
                    localStorage.setItem("USER_LONGITUDE", position.coords.longitude.toString());
                },
                function errorCallback(error) {
                    localStorage.setItem("USER_LATITUDE", "-23.5695299");
                    localStorage.setItem("USER_LONGITUDE", "-46.5945138");
                }
            );
        }

        var info = {
            origem: "",
            destino: ""
        }
        for (var i = 0; i < this.regioes.length; i++) {

            if (i == 0) {
                info.destino = info.destino + this.regioes[i].CorreioCEP;
            }
            else {
                info.destino += "%7C" + this.regioes[i].CorreioCEP;
            }
        }
        info.origem = localStorage.getItem("USER_LATITUDE") + "," + localStorage.getItem("USER_LONGITUDE");

        var getInfoKms = [];
        this._helperService.getLocationKMs(info).then((data: any) => {
            try {
                data = data.payload;
                if (data.status == "OK") {
                    var aux = data.rows[0].elements;
                    if (aux.length > 0) {
                        for (var k = 0; k < aux.length; k++) {
                            var objj = { "id": k, "value": aux[k].duration.value }
                            getInfoKms.push(objj);
                        }
                        var info = getInfoKms.sort((exp1, exp2) => exp1.value - exp2.value);
                        var idFranquia = this.regioes[info[0].id].FranquiaID;
                        this.sendBDInsert(idFranquia);
                    }
                }
                else {
                    this.sendBDInsert(1)
                }
            } catch (e) {
                this.sendBDInsert(1)
            }

        });
    }

    sendBDInsert(FranquiaID) {
        var refData;
        try {
            refData = localStorage.getItem('pipoohRef');
            if (refData == null) { refData = ""; }
        } catch (e) {
            refData = "";
        }
        localStorage.removeItem('pipoohRef');


        // var obj = { "name": this.model.fullname, "email": this.model.email, "cpf": cpf, "password": this.model.password, "sexo": this.model.apresentacao, "celular": this.model.celular, "ultimaMenstruacao": this.model.ultimaMenstruacao, "ref": refData, "codigo": this.model.codigo, "fkFranquia": FranquiaID, "tipo": this.model.tipo, "nomeCrianca": this.model.nomeCrianca, "sexoCrianca": this.model.sexoCrianca, "dataNascimentoCrianca": this.model.dataNascimentoCrianca };
        // this.userService.userInsert(obj).then(data => {
        //     this.user = data;
        //     console.log("user", this.user)
        // }).catch(function (e) {
        //     this._alertService.error(
        //         'Falha no cadastro.',
        //         true);
        //     this.loading = false;
        // });
        // if (this.user.payload.length > 0) {
        //     if (this.user.payload.length == 0) {
        //         this._alertService.error(
        //             'Falha no cadastro.',
        //             true);
        //         this.loading = false;
        //     }
        //     if (String(this.user.payload).substring(0, 15) == "SQLSTATE[23000]") {
        //         this.loading = false;
        //         this.toastr.error('Falha no cadastro', 'E-mail informado já está cadastrado');
        //     }
        //     else {
        //         // localStorage.setItem('pipoohNewHome', '1');
        //         eval("fbq('init', '469756370940862');");
        //         eval("fbq('track', 'CadastroClick');");
        //         this.toastr.success('Cadastro realizado com sucesso');
        //         this._authService.rdConversao(this.model.fullname, this.model.email, this.model.celular, this.model.ultimaMenstruacao, refData, this.model.tipo, this.model.nomeCrianca, this.model.sexoCrianca, this.model.dataNascimentoCrianca);
        //         localStorage.setItem('pipoohNewResgate', '1');
        //         localStorage.setItem('pipoohNewConvite', '1');
        //         localStorage.setItem('pipoohNewRifa', '1');
        //         localStorage.setItem('pipoohNewProfile', '1');
        //         localStorage.setItem('pipoohNewProfileCha', '1');
        //         localStorage.setItem('pipoohNewListProfile', '1');
        //         localStorage.setItem('pipoohUser', this.user.payload[0].token);
        //         this._router.navigate(['/boasVindas']);
        //         // document.getElementById("openModalAutenticacaoEmail").click();
        //         this.loading = false;
        //     }
        // }
        // else {
        //     this._alertService.error(
        //         'Falha no cadastro.',
        //         true);
        //     this.loading = false;
        //     this.loading = false;
        //     return false;
        // }

        this._authService.signUp(this.model.fullname, this.model.apresentacao, this.model.email, this.model.cpf, this.model.password, this.model.rpassword, this.model.celular, this.model.ultimaMenstruacao, refData, this.model.codigo, FranquiaID, this.model.tipo, this.model.nomeCrianca, this.model.sexoCrianca, this.model.dataNascimentoCrianca, this.model.captacao).then(data => {
            if (data == false) {
                this._alertService.error(
                    'Falha no cadastro.',
                    true);
            }
            else {
                if (data == "E-mail cadastrado") {
                    this.toastr.error('Falha no cadastro', 'E-mail informado já está cadastrado');
                }
                else {
                    eval("fbq('init', '469756370940862');");
                    eval("fbq('track', 'CadastroClick');");
                    this._alertService.success(
                        'Cadastro realizado com sucesso',
                        true);
                    this._authService.rdConversao(this.model.fullname, this.model.email, this.model.celular, this.model.ultimaMenstruacao, refData, this.model.tipo, this.model.nomeCrianca, this.model.sexoCrianca, this.model.dataNascimentoCrianca, this.model.captacao);
                    this._router.navigate(['/boasVindas']);
                    // document.getElementById("openModalAutenticacaoEmail").click();
                    //this._router.navigate(['/profile', { tab: 'bebe', complete: true }]);

                }

            }
            this.loading = false;
        })
            .catch(e => {
            })
    }
    autenticarEmail() {
        this.authService.autenticarEmail({ "fkUsuario": this.user.payload[0].UserID, "codigo": this.codigo }).then(data => {
            console.log("codigo", data)
            if (data.payload.length > 0) {
                localStorage.setItem('pipoohNewResgate', '1');
                localStorage.setItem('pipoohNewConvite', '1');
                localStorage.setItem('pipoohNewRifa', '1');
                localStorage.setItem('pipoohNewProfile', '1');
                localStorage.setItem('pipoohNewProfileCha', '1');
                localStorage.setItem('pipoohNewListProfile', '1');
                localStorage.setItem('pipoohUser', this.user.payload[0].token);

                this._router.navigate(['/boasVindas']);
            }
            else {
                this.toastr.error("Código inválido!");
            }
        })
    }

    signUpOrganizador() {
        this.loading = true;
        // return;
        if (!this.cap) {
            this.toastr.error("Por favor, verifique que não é um robô")
            this.loading = false;
            return;
        }
        if (!this.organizador.agree) {
            this.toastr.error("Por favor, concorde com os termos de uso e condições.")
            this.loading = false;
            return;
        }
        if (!this.organizador.politica) {
            this.toastr.error("Por favor, concorde com as política de privacidade.")
            this.loading = false;
            return;
        }
        var aux: any;

        if (this.organizador.nome == "" || this.organizador.nome == null) { this.toastr.error('Campo nome é obrigátorio', 'Falha no cadastro'); this.loading = false; return false; }
        if (this.organizador.email == "" || this.organizador.email == null) { this.toastr.error('Campo e-mail é obrigátorio', 'Falha no cadastro'); this.loading = false; return false; }
        if (this.organizador.senha != this.organizador.rsenha) { this.toastr.error('As senhas não coincidem', 'Falha no cadastro'); this.loading = false; return false; }
        if (this.organizador.senha == "" || this.organizador.senha == null) { this.toastr.error('Campo senha é obrigátorio', 'Falha no cadastro'); this.loading = false; return false; }
        if (this.organizador.captacao == "" || !this.organizador.captacao) { this.toastr.error('Informe onde conheceu a Pipooh'); this.loading = false; return false; }
        this._organizador.inserirOrganizador({ "nome": this.organizador.nome, "email": this.organizador.email, "telefone": this.organizador.telefone, "senha": this.organizador.senha, "captacao": this.organizador.captacao }).then(data => {
            if (data.success) {
                this.toastr.success("Conta criada com sucesso!");
                localStorage.setItem('pipoohUser', data.payload[0].token);
                this._rdSationService.rdConversaoOrganizador({ "nome": this.organizador.nome, "email": this.organizador.email, "telefone": this.organizador.telefone, "captacao": this.organizador.captacao }).then(data => { });
                this._router.navigate(['/organizador']);
                // var url = "http://localhost:4200/organizador";
                // window.location.href = url;
            } else {
                this.loading = false;
            }
        })
    }

    singUpEstabelecimento() {
        if (this.estabelecimento.razaoSocial == "" || this.estabelecimento.razaoSocial == null) { this.toastr.error('Campo razão social é obrigátorio', 'Falha no cadastro'); this.loading = false; return false; }
        if (this.estabelecimento.nomeFantasia == "" || this.estabelecimento.nomeFantasia == null) { this.toastr.error('Campo nome fantasia é obrigátorio', 'Falha no cadastro'); this.loading = false; return false; }
        if (this.estabelecimento.password != this.estabelecimento.rpassword) { this.toastr.error('As senhas não coincidem', 'Falha no cadastro'); this.loading = false; return false; }
        if (this.estabelecimento.password == "" || this.estabelecimento.password == null) { this.toastr.error('Campo senha é obrigátorio', 'Falha no cadastro'); this.loading = false; return false; }
        if (this.estabelecimento.telefoneFinanceiro == "" || this.estabelecimento.telefoneFinanceiro == null) { this.toastr.error('Campo telefone financeiro é obrigátorio', 'Falha no cadastro'); this.loading = false; return false; }
        if (this.estabelecimento.email == "" || this.estabelecimento.email == null) { this.toastr.error('Campo email é obrigátorio', 'Falha no cadastro'); this.loading = false; return false; }
        if (this.estabelecimento.categoria == "" || this.estabelecimento.categoria == null) { this.toastr.error('Campo categoria é obrigátorio', 'Falha no cadastro'); this.loading = false; return false; }
        if (this.estabelecimento.modalidade == "" || this.estabelecimento.modalidade == null) { this.toastr.error('Campo modalidade é obrigátorio', 'Falha no cadastro'); this.loading = false; return false; }
        if (this.estabelecimento.agencia == "" || this.estabelecimento.agencia == null) { this.toastr.error('Campo agencia é obrigátorio', 'Falha no cadastro'); this.loading = false; return false; }
        if (this.estabelecimento.banco == "" || this.estabelecimento.banco == null) { this.toastr.error('Campo banco é obrigátorio', 'Falha no cadastro'); this.loading = false; return false; }
        if (this.estabelecimento.conta == "" || this.estabelecimento.conta == null) { this.toastr.error('Campo conta é obrigátorio', 'Falha no cadastro'); this.loading = false; return false; }
        if (cnpj.isValid(this.estabelecimento.cnpj) == false) { this.toastr.error('CNPJ inválido', 'Falha no cadastro'); this.loading = false; return false; }
        this._estabelecimentoService.inserirEstabelecimento(this.estabelecimento).then(data => {
            var aux = data;
        })

    }
    forgotPass() {
        this.loading = true;
        var aux: any = [];
        console.log("aqui")

        this._userService.resetSenha({ "email": this.model.email }).then(data => {
            aux = data;
            this.showAlert('alertSignin');
            if (data.success) {
                this._alertService.success(
                    'Legal! A instrução de recuperação de senha foi enviada para seu e-mail.',
                    true);
                LoginCustom.displaySignInForm();
            }
            // else {
            //     this._alertService.success(
            //         'Legal! A instrução de recuperação de senha foi enviada para seu e-mail.',
            //         true);
            //     //this._router.navigate([this.returnUrl]);
            //     LoginCustom.displaySignInForm();
            // }
            this.loading = false;
            this.model = {};
        })
    }

    showAlert(target) {
        this[target].clear();
        let factory = this.cfr.resolveComponentFactory(AlertComponent);
        let ref = this[target].createComponent(factory);
        ref.changeDetectorRef.detectChanges();
    }
}