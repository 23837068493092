import { Component, OnInit, ViewEncapsulation, EventEmitter, Output } from '@angular/core';
import { Helpers } from '../../../helpers';
import { AuthService } from '../../../admin/_services/auth.service';
import { environment } from '../../../../environments/environment.prod';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";
import { RankingPromocaoService } from '../../../admin/_services/rankingPromocao.service';
import { PedidoService } from '../../../admin/_services/pedido.service';
import { CategoriaPontuacaoService } from '../../../admin/_services/categoriaPontuacao.service';
import { CupomPedidoService } from '../../../admin/_services/cupomPedido.service';
import { ResgateService } from '../../../admin/_services/resgate.service';
import { BehaviourService } from '../../../admin/_services/behaviour.service';
import { CarteiraService } from '../../../admin/_services/carteira.service';
import { ClipboardService } from 'ngx-clipboard'
import { BehaviorSubject, Subscription } from 'rxjs';


declare let mLayout: any;
@Component({
    selector: "app-header-nav",
    templateUrl: "./header-nav.component.html",
    encapsulation: ViewEncapsulation.None,
})
export class HeaderNavComponent implements OnInit {
    musicaSubscription: Subscription;
    musicaCount = 0;
    public URL = `${environment.domain}`;
    public URLNova = "https://img.pipooh.com.br/";
    @Output() usuario = new EventEmitter();
    infoUser: any = [];
    imagem: any;
    pedidos: any = [];
    // Promoção
    produtosPromocao: any = [];
    pontos: any;
    pontosAcumulados: any;
    categoria: any;
    categorias: any = [];
    proximaCategoria: any = [];
    //Cupom
    cupomPedido: any;
    addCupom: any = false;
    //credito
    valor: any;
    pedido: any = [];
    tokenPedido: any;
    //extrato
    extrato: any = [];
    presentes: any;
    modulo: any = {
        aguardando: false,
        bonus: false
    }
    //cartoes
    cartoes: any = [];
    cartao: any = [];
    metodoPagamento: any = [];
    //pix
    qrCode: any;
    qrCodeIMG: any;
    more: boolean = false;
    loading: any = false;
    //produtos
    // produtos: any = 0;
    constructor(private _router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private _authService: AuthService,
        private _rankingPromocaoService: RankingPromocaoService,
        private _pedidoService: PedidoService,
        private _categoriaPontuacaoService: CategoriaPontuacaoService,
        private _cupomPedidoService: CupomPedidoService,
        private _resgateService: ResgateService,
        private _behaviour: BehaviourService,
        private _carteiraService: CarteiraService,
        private _clipboardService: ClipboardService,
    ) {

    }
    ngOnInit() {
        this.metodoPagamento = "Pix";
        this.getInfoUser();

        this._resgateService.carrinhoSize({}).then(data => {
            var valor = data.payload[0].quantidadeProdutos;
            this._resgateService.alterarCarrinho(valor);
          })

    }
    get produtos(): string {
        return this._resgateService.obterCarrinho().value;
    }
    getInfoUser() {
        let data = localStorage.getItem('pipoohUser');
        this._authService.getToken({ "token": data }).then(data => {
            this.infoUser = data;
            this.infoUser = this.infoUser.payload[0];
            this._authService.setUser(this.infoUser);
            if (this.infoUser == false || this.infoUser == null) { this._authService.logout(); this._router.navigate([`/logout`]); }

            this.imagem = this.URLNova + this.infoUser.UserFoto;
            this.buscarPontos();
            this.buscarCartoes();
            this.pedidosPagos(this.infoUser.UserID);
            this.getExtrato(this.infoUser.UserID);
            // this.buscarCarrinho();


        }).catch(e => { console.log(e); });
    }
    formatDecimal(string) {
        if (string != "") {
            return parseFloat(string).toFixed(2);
        }
    }
    formatMilhares(string) {
        if (string != "") {
            return string.toLocaleString('pt-BR');
        }
    }

    getExtrato(user) {
        this._resgateService.getExtrato({ "userID": user }).then(data => {
            var aux: any = data;
            this.extrato = aux.payload;
            this.presentes = aux.presentes_ganhos;
        })
    }

    buscarPontos() {
        this._rankingPromocaoService.buscarRanking({}).then(data => {
            this.buscarCategoriaPontuacao();
            var aux = data.payload[0];
            this.categoria = aux.categoria;
            this.pontos = aux.pontosResgataveis;
            this.pontosAcumulados = aux.pontosAcumulados;
        });
    }
    pedidosPagos(user) {
        this._pedidoService.pedidosPagos({ "userID": user }).then(data => {
            this.pedidos = data;
            $("#LoadRelatorio").css("display", "none");
        })
    }

    buscarCategoriaPontuacao() {
        this._categoriaPontuacaoService.buscarCategorias({}).then(data => {
            this.categorias = data.payload;
            for (var i = 0; i < this.categorias.length; i++) {
                if (this.categorias[i].categoria == this.categoria && this.categoria != "Vip") {
                    this.proximaCategoria = this.categorias[i + 1];
                }
            }
        });
    }

    getValor() {
        this.valor = this.route.snapshot.params['valor'];
        if (this.valor == undefined) {
            document.getElementById("openModalValor").click();
        }
        else {
            this.valor = this.formatDecimal(this.valor);
        }
    }

    addPedido() {
        this.loading = true;
        if (!this.infoUser.UserCPF) {
            this.loading = false;
            this.toastr.error("Cadastre seu CPF antes de continuar!");
            return;
        }
        if (!this.infoUser.UserTelefone) {
            this.loading = false;
            this.toastr.error("Cadastre seu telefone antes de continuar!");
            return;
        }
        if (this.cupomPedido != "" && this.cupomPedido != null) {
            this._cupomPedidoService.buscarCupomNome({ "nome": this.cupomPedido }).then(data => {
                var cupom = data.payload;
                if (cupom.length == 0) {
                    this.toastr.error("Cupom não encontrado");
                    this.loading = false;
                    return;
                } else {
                    cupom[0].dataValidade = new Date(cupom[0].dataValidade)
                    if (cupom[0].ativo == false || cupom[0].quantidade >= cupom[0].quantidadeLimite || cupom[0].dataValidade.getTime() < Date.now()) {
                        this.toastr.error("Cupom indisponível");
                        this.loading = false;
                        return;
                    } else {
                        var obj = {
                            pPedidoValor: this.valor.substr(2),
                            pCompradorNome: this.infoUser.UserName,
                            pCompradorEmail: this.infoUser.UserEmail,
                            pCompradorCPF: this.infoUser.UserCPF,
                            pCompradorDDD: this.infoUser.UserTelefone.substr(0, 2),
                            pCompradorTelefone: this.infoUser.UserTelefone.substr(2),
                            cupom: this.cupomPedido
                        }
                        this._pedidoService.pedidosInsertCredito(obj).then(data => {
                            var aux: any = data;
                            this.pedido = aux.payload;
                            for (var i = 0; i < this.pedido.length; i++) {
                                this.tokenPedido = this.pedido[i].token
                            }
                            this.goToUrl()
                            this.loading = false;
                        });
                    }
                }
            })
        } else {
            var obj = {
                pPedidoValor: this.valor.substr(2),
                pCompradorNome: this.infoUser.UserName,
                pCompradorEmail: this.infoUser.UserEmail,
                pCompradorCPF: this.infoUser.UserCPF,
                pCompradorDDD: this.infoUser.UserTelefone.substr(0, 2),
                pCompradorTelefone: this.infoUser.UserTelefone.substr(2),
                cupom: this.cupomPedido
            }
            this._pedidoService.pedidosInsertCredito(obj).then(data => {
                var aux: any = data;
                this.pedido = aux.payload;
                console.log("data", data)
                for (var i = 0; i < this.pedido.length; i++) {
                    this.tokenPedido = this.pedido[i].token
                }
                this.loading = false;
                this.goToUrl()
            }).catch(e => {
                this.loading = false;
            });
        }
    }

    addPedidoCartao() {
        this.loading = true;
        if (!this.valor) {
            this.loading = false;
            this.toastr.error("Valor inválido!");
            return;
        }
        if (!this.infoUser.UserCPF) {
            this.loading = false;
            this.toastr.error("Cadastre seu CPF antes de continuar!");
            return;
        }
        if (!this.infoUser.UserTelefone) {
            this.loading = false;
            this.toastr.error("Cadastre seu telefone antes de continuar!");
            return;
        }
        if (this.cupomPedido != "" && this.cupomPedido != null) {
            this._cupomPedidoService.buscarCupomNome({ "nome": this.cupomPedido }).then(data => {
                var cupom = data.payload;
                if (cupom.length == 0) {
                    this.loading = false;
                    this.toastr.error("Cupom não encontrado");
                    return;
                } else {
                    cupom[0].dataValidade = new Date(cupom[0].dataValidade)
                    if (cupom[0].ativo == false || cupom[0].quantidade >= cupom[0].quantidadeLimite || cupom[0].dataValidade.getTime() < Date.now()) {
                        this.toastr.error("Cupom indisponível");
                        this.loading = false;
                        return;
                    } else {
                        var obj = {};
                        var metodo = "Cartão";
                        if (this.metodoPagamento == "Pix") {
                            metodo = "Pix"
                            obj = {
                                pPedidoValor: this.valor.substr(2),
                                pCompradorNome: this.infoUser.UserName,
                                pCompradorEmail: this.infoUser.UserEmail,
                                pCompradorCPF: this.infoUser.UserCPF,
                                pCompradorDDD: this.infoUser.UserTelefone.substr(0, 2),
                                pCompradorTelefone: this.infoUser.UserTelefone.substr(2),
                                cupom: this.cupomPedido,
                                metodoPagamento: metodo
                            }
                        } else if (this.metodoPagamento == "PixStripe") {
                            metodo = "PixStripe"
                            obj = {
                                pPedidoValor: this.valor.substr(2),
                                pCompradorNome: this.infoUser.UserName,
                                pCompradorEmail: this.infoUser.UserEmail,
                                pCompradorCPF: this.infoUser.UserCPF,
                                pCompradorDDD: this.infoUser.UserTelefone.substr(0, 2),
                                pCompradorTelefone: this.infoUser.UserTelefone.substr(2),
                                cupom: this.cupomPedido,
                                metodoPagamento: metodo
                            }
                        } else if (this.metodoPagamento == "Boleto") {
                            metodo = "Boleto"
                            if (!this.infoUser.UserCEP) {
                                this.loading = false;
                                this.toastr.error("Cadastre seu endereço para continuar.");
                                return;
                            }
                            obj = {
                                pPedidoValor: this.valor.substr(2),
                                pCompradorNome: this.infoUser.UserName,
                                pCompradorEmail: this.infoUser.UserEmail,
                                pCompradorCPF: this.infoUser.UserCPF,
                                pCompradorEndereco: this.infoUser.UserEndereco,
                                pCompradorNumero: this.infoUser.UserNumero,
                                pCompradorBairro: this.infoUser.UserBairro,
                                pCompradorCidade: this.infoUser.UserCidade,
                                pCompradorUF: this.infoUser.UserUF,
                                pCompradorCEP: this.infoUser.UserCEP,
                                pCompradorDDD: this.infoUser.UserTelefone.substr(0, 2),
                                pCompradorTelefone: this.infoUser.UserTelefone.substr(2),
                                cupom: this.cupomPedido,
                                metodoPagamento: metodo
                            }
                        } else {
                            obj = {
                                pPedidoValor: this.valor.substr(2),
                                pCompradorNome: this.infoUser.UserName,
                                pCompradorEmail: this.infoUser.UserEmail,
                                pCompradorCPF: this.infoUser.UserCPF,
                                pCompradorDDD: this.infoUser.UserTelefone.substr(0, 2),
                                pCompradorTelefone: this.infoUser.UserTelefone.substr(2),
                                cupom: this.cupomPedido,
                                fkCarteira: this.cartao.id,
                                metodoPagamento: metodo
                            }
                        }
                        this._pedidoService.pedidosInsertPagamento(obj).then(data => {
                            this.loading = false;
                            if (metodo == "Cartão") {
                                if (data.payload.charges[0].status == "PAID") {
                                    this.getInfoUser();
                                    this.loading = false;
                                    document.getElementById("openModalValor").click();
                                    this.toastr.success("Crédito inserido com sucesso!")
                                } else {
                                    this.loading = false;
                                    this.toastr.error("Tente com outra forma de pagamento!", "Erro no pagamento")
                                }
                            }
                            if (metodo == "Pix") {
                                if (data.payload.error_messages) {
                                    this.loading = false;
                                    this.toastr.error("CPF inválido, confira seus dados.");
                                    return;
                                } else {
                                    this.loading = false;
                                    this.qrCode = data.payload.qr_codes[0].text;
                                    this.qrCodeIMG = data.payload.qr_codes[0].links[0].href;
                                    this._router.navigate(["credito", { 'qrCode': this.qrCode, "qrCodeIMG": this.qrCodeIMG }]);
                                    this.more = false;
                                    document.getElementById("openModalValor").click();
                                    document.getElementById("openModalPix").click();
                                }
                            }
                            if (metodo == "PixStripe") {
                                if (data.success) {
                                    this.loading = false;
                                    this.qrCode = data.payload.next_action.pix_display_qr_code.data;
                                    this.qrCodeIMG = data.payload.next_action.pix_display_qr_code.image_url_png;
                                    this._router.navigate(["credito", { 'qrCode': this.qrCode, "qrCodeIMG": this.qrCodeIMG }]);
                                    this.more = false;
                                    document.getElementById("openModalValor").click();
                                    document.getElementById("openModalPix").click();
                                }
                            }
                            if (metodo == "Boleto") {
                                this.loading = false;
                                var url = data.payload.links[0].href;
                                window.open(url, '_blank');
                                document.getElementById("openModalValor").click();
                            }

                        }).catch(e => { this.loading = false; });
                    }
                }
            })
        } else {
            var obj = {};
            var metodo = "Cartão";
            if (this.metodoPagamento == "Pix") {
                metodo = "Pix"
                obj = {
                    pPedidoValor: this.valor.substr(2),
                    pCompradorNome: this.infoUser.UserName,
                    pCompradorEmail: this.infoUser.UserEmail,
                    pCompradorCPF: this.infoUser.UserCPF,
                    pCompradorDDD: this.infoUser.UserTelefone.substr(0, 2),
                    pCompradorTelefone: this.infoUser.UserTelefone.substr(2),
                    cupom: this.cupomPedido,
                    metodoPagamento: metodo
                }
            } else if (this.metodoPagamento == "PixStripe") {
                metodo = "PixStripe"
                obj = {
                    pPedidoValor: this.valor.substr(2),
                    pCompradorNome: this.infoUser.UserName,
                    pCompradorEmail: this.infoUser.UserEmail,
                    pCompradorCPF: this.infoUser.UserCPF,
                    pCompradorDDD: this.infoUser.UserTelefone.substr(0, 2),
                    pCompradorTelefone: this.infoUser.UserTelefone.substr(2),
                    cupom: this.cupomPedido,
                    metodoPagamento: metodo
                }
            } else if (this.metodoPagamento == "Boleto") {
                metodo = "Boleto"
                if (!this.infoUser.UserCEP) {
                    this.loading = false;
                    this.toastr.error("Cadastre seu endereço para continuar.");
                    return;
                }
                obj = {
                    pPedidoValor: this.valor.substr(2),
                    pCompradorNome: this.infoUser.UserName,
                    pCompradorEmail: this.infoUser.UserEmail,
                    pCompradorCPF: this.infoUser.UserCPF,
                    pCompradorEndereco: this.infoUser.UserEndereco,
                    pCompradorNumero: this.infoUser.UserNumero,
                    pCompradorBairro: this.infoUser.UserBairro,
                    pCompradorCidade: this.infoUser.UserCidade,
                    pCompradorUF: this.infoUser.UserUF,
                    pCompradorCEP: this.infoUser.UserCEP,
                    pCompradorDDD: this.infoUser.UserTelefone.substr(0, 2),
                    pCompradorTelefone: this.infoUser.UserTelefone.substr(2),
                    cupom: this.cupomPedido,
                    metodoPagamento: metodo
                }
            } else {
                obj = {
                    pPedidoValor: this.valor.substr(2),
                    pCompradorNome: this.infoUser.UserName,
                    pCompradorEmail: this.infoUser.UserEmail,
                    pCompradorCPF: this.infoUser.UserCPF,
                    pCompradorDDD: this.infoUser.UserTelefone.substr(0, 2),
                    pCompradorTelefone: this.infoUser.UserTelefone.substr(2),
                    cupom: this.cupomPedido,
                    fkCarteira: this.cartao.id,
                    metodoPagamento: metodo
                }
            }
            this._pedidoService.pedidosInsertPagamento(obj).then(data => {
                this.loading = false;
                if (metodo == "Cartão") {
                    if (data.payload.charges[0].status == "PAID") {
                        this.getInfoUser();
                        this.loading = false;
                        document.getElementById("openModalValor").click();
                        this.toastr.success("Crédito inserido com sucesso!")
                    } else {
                        this.toastr.error("Tente com outra forma de pagamento!", "Erro no pagamento")
                    }
                }
                if (metodo == "Pix") {
                    if (data.payload.error_messages) {
                        this.toastr.error("CPF inválido, confira seus dados.");
                        return;
                    } else {
                        this.qrCode = data.payload.qr_codes[0].text;
                        this.qrCodeIMG = data.payload.qr_codes[0].links[0].href;
                        this._router.navigate(["credito", { 'qrCode': this.qrCode, "qrCodeIMG": this.qrCodeIMG }]);
                        this.more = false;
                        this.loading = false;
                        document.getElementById("openModalValor").click();
                        document.getElementById("openModalPix").click();
                    }
                }
                if (metodo == "PixStripe") {
                    if (data.success) {
                        this.loading = false;
                        this.qrCode = data.payload.next_action.pix_display_qr_code.data;
                        this.qrCodeIMG = data.payload.next_action.pix_display_qr_code.image_url_png;
                        this._router.navigate(["credito", { 'qrCode': this.qrCode, "qrCodeIMG": this.qrCodeIMG }]);
                        this.more = false;
                        document.getElementById("openModalValor").click();
                        document.getElementById("openModalPix").click();
                    }
                }
                if (metodo == "Boleto") {
                    this.loading = false;
                    var url = data.payload.links[0].href;
                    window.open(url, '_blank');
                    document.getElementById("openModalValor").click();
                }
            }).catch(e => { this.loading = false; });
        }
        // this.loading = false;
    }

    goToUrl() {
        var url = "https://checkout.pipooh.com.br/?id=" + this.tokenPedido;
        window.location.href = url;
    }

    openInfo(info) {
        this.modulo = { aguardando: false, bonus: false, indicacao: false }
        switch (info) {
            case "aguardando":
                if (this.modulo.aguardando == true) { this.modulo.aguardando = false; } else { this.modulo.aguardando = true; }
                break
            case "bonus":
                if (this.modulo.bonus == true) { this.modulo.bonus = false; } else { this.modulo.bonus = true; }
                break
            case "indicacao":
                if (this.modulo.indicacao == true) { this.modulo.indicacao = false; } else { this.modulo.indicacao = true; }
                break
        }
    }

    buscarCartoes() {
        this._carteiraService.buscarCartoesValidado({}).then(data => {
            this.cartoes = data.payload;
            if (this.cartoes.length > 0) {
                this.cartao = this.cartoes[0];
                this.metodoPagamento = 0;
            }
        })
    }
    // buscarCarrinho() {
    //     this._resgateService.carrinhoSize({}).then(data => {
    //         this.produtos = data.payload[0].quantidadeProdutos;
    //         console.log("produtos", this.produtos)
    //     })
    // }
    mudancaPagamento() {
        if (this.metodoPagamento == "") {
            this.cartao = null
        } else {
            this.cartao = this.cartoes[this.metodoPagamento];
        }
    }
    codigoCopiado() {
        this._clipboardService.copy("teste");
        this.toastr.success("Codigo pix copiado com sucesso!");
    }
    insertButton(button) {
        let data = localStorage.getItem('pipoohUser');

        this._behaviour.insertButton({ "token": data, "clickButton": button });
    }
}