import { Injectable } from '@angular/core';
import { environment, environmentAspNet } from '../../../environments/environment.prod';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root'})
export class UserService {

    private URL = `${environment.api}`;
    private URLAspNet = `${environmentAspNet.api}`;
    userToken: any = localStorage.getItem('pipoohUser');

    constructor(private http: HttpClient,
        private toastr: ToastrService) { }

    authFormData(formData) {
        this.userToken = localStorage.getItem('pipoohUser');
        formData.token = this.userToken;
        return formData;
    }

    postData(url, formData): Promise<any>
    {
        return this.http.post(url, formData).toPromise().then(response =>
        {
            var aux: any = response;
            if (aux.success == false)
            {
                this.toastr.error(aux.error, "Erro de solicitação");
                return aux;
            }
            else 
            {
                return aux;
            }
        });
    
    }

    getSaldo(formData) {
        return this.postData(`${this.URL}user/saldo`, formData);
    }
    getPresenca(formData) {
        return this.postData(`${this.URL}user/presenca`, formData);
    }
    getPromocao(formData) {
        return this.postData(`${this.URL}user/promocao`, formData);
    }
    checkMail(formData) {
        return this.postData(`${this.URL}user/check/mail`, formData);
    }
    setApresentacao(formData) {
        return this.postData(`${this.URL}user/apresentacao`, formData);
    }
    updateDados(formData) {
        return this.postData(`${this.URLAspNet}user/app/update/dados`, this.authFormData(formData));
    }
    updatePassword(formData) {
        return this.postData(`${this.URL}user/update/password`, formData);
    }
    updateBebe(formData) {
        return this.postData(`${this.URL}user/update/bebe`, formData);
    }
    updateEndereco(formData) {
        return this.postData(`${this.URL}user/update/endereco`, formData);
    }
    updateCha(formData) {
        return this.postData(`${this.URL}user/update/cha`, formData);
    }
    updateEnderecoWithFranquia(formData) {
        return this.postData(`${this.URL}user/update/enderecoWithFranquia`, formData);
    }
    selecionarUsuarioTransferencia(formData) {
        return this.postData(`${this.URLAspNet}user/select/transferencia`, this.authFormData(formData));
    }
    inserirImagemPerfil(formData) {
        return this.postData(`${this.URLAspNet}user/app/upload/foto`, this.authFormData(formData));
    }
    updateUserFrete(formData) {
        return this.postData(`${this.URLAspNet}user/update/frete`, this.authFormData(formData));
    }
    userInsert(formData) {
        return this.postData(`${this.URLAspNet}user/app/insert`, formData);
    }
    updateGestante(formData) {
        return this.postData(`${this.URLAspNet}user/app/update/gestante`, this.authFormData(formData));
    }
    updateNomeCha(formData) {
        return this.postData(`${this.URLAspNet}user/app/update/nomeCha`, this.authFormData(formData));
    }
    buscarUsuarioChaOrganizador(formData) {
        return this.postData(`${this.URLAspNet}user/app/select/chaOrganizador`, this.authFormData(formData));
    }
    editarDadosChaOrganizador(formData) {
        return this.postData(`${this.URLAspNet}user/app/update/dadosCha/chaOrganizador`, this.authFormData(formData));
    }
    salvarFotoChaOrganizador(formData) {
        return this.postData(`${this.URLAspNet}user/app/update/foto/chaOrganizador`, this.authFormData(formData));
    }
    buscarUsuarioEmailOrganizador(formData) {
        return this.postData(`${this.URLAspNet}user/app/select/email/organizador`, this.authFormData(formData));
    }
    updateSenha(formData) {
        return this.postData(`${this.URLAspNet}user/app/update/senha`, this.authFormData(formData));
    }
    resetSenha(formData) {
        return this.postData(`${this.URLAspNet}user/app/reset/senha`, formData);
    }
    buscarCpf(formData) {
        return this.postData(`${this.URLAspNet}user/app/select/cpf`, formData);
    }
}
